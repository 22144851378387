<template>
  <div class="step">
    <template v-if="!submitted">
      <h4>登録することで、<a class="text-green" :href="hostName + '/term'" target="_blank">利用規約</a>・<a class="text-green" target="_blank" :href="hostName + '/policy'">個人情報の取扱いについて</a>に同意したものとみなします。</h4>

      <div class="step__register">
        <!-- <ValidationObserver
          tag="form"
          ref="observer"
          class="step__block step__block--mail"
          @submit.prevent="validateBeforeSubmit()">
          <ul v-if="errors"
              class="error__server">
            <li>{{ errors }}</li>
          </ul>

          <InputField v-model="form.email"
                      field="メールアドレス"
                      vid="email"
                      placeholder="メールアドレスを入力してください"
                      rules="required|email|max:200"
                      :prefix="true">
            <template #prefix>
              <MailIcon size="22"
                        class="prefix"/>
            </template>
          </InputField>

          <button type="submit"
                  class="btn__register">
            メールアドレスで登録
            <img src="@/assets/images/icon/icon-bracket.svg" alt="icon">
          </button>

          <ul>
            <li>※ドメイン指定受信をされている場合、「chibijob.com」からのメールを受信できるようご設定ください。</li>
            <li>※お使いのメールソフトによっては、メールが「迷惑メール」<br class="pc">フォルダに入る場合がございます。メールが届かない場合<br class="pc">は、迷惑フォルダもご確認ください。</li>
          </ul>

        </ValidationObserver>

        <p class="txt__or">または</p> -->

        <div class="step__block step__block--sn center">
          <!-- <button @click.prevent="handleRedirectSNS('yahoo')"
                  class="btn__register btn__register--sn">
            <span class="icon">
              <img src="@/assets/images/icon/icon-yahoo.svg" alt="icon">
            </span>
            Yahoo! Japan ID で登録する
          </button>
          <button @click.prevent="handleRedirectSNS('google')"
                  class="btn__register btn__register--sn">
            <span class="icon">
              <img src="@/assets/images/icon/icon-google.svg" alt="icon">
            </span>
            Google アカウントで登録する
          </button> -->
          <button @click.prevent="handleRedirectSNS('line')"
                  class="btn__register btn__register--sn">
            <span class="icon">
              <img src="@/assets/images/icon/icon-line.svg" alt="icon">
            </span>
            LINE アカウントで登録する
          </button>
          <ul v-if="lineRegisteredMessageError"
              class="error__server normal">
            <li v-html="lineRegisteredMessageError"></li>
          </ul>
        </div>
      </div>
    </template>

    <template v-else>
      <h4>仮登録メールを送信しました。</h4>

      <div class="step__registered">
        <!--Mail-->
        <div class="mail">
          <img src="@/assets/images/icon/icon-mail.svg" alt="icon">
          {{ form.email }}
        </div>

        <ul class="note01">
          <li>上記のメールアドレス宛に確認メールを送信しました。</li>
          <li>メール内に記載されたURLにアクセスし、必要事項を入力してください。</li>
        </ul>

        <ul class="note02">
          <li>※ドメイン指定受信をされている場合、「chibijob.com」からのメールを受信できるようご設定ください。</li>
          <li>※お使いのメールソフトによっては、メールが「迷惑メール」フォルダに入る場合がございます。メー ルが届かない場合は、迷惑フォルダもご確認ください。</li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import store from '@/store'
// import InputField from '@/components/form/InputField'
// import { MailIcon } from 'vue-feather-icons'
import { mapActions } from 'vuex'
import CommonMixin from '@/mixins/common.mixin'
import { RESPONSE_STATUS } from '@/enum/response'

export default {
  name: 'Step01',

  components: {
    // InputField,
    // MailIcon
  },

  mixins: [CommonMixin],

  data () {
    return {
      form: {
        email: '',
        invite_code: null,
        a8_custom: Cookie.get('a8_custom') || null
      },
      errors: null,
      lineRegisteredMessageError: null,
      submitted: false,
      hostName: window.location.origin
    }
  },

  beforeRouteLeave (to, from, next) {
    store.commit('auth/SET_MESSAGE_ERR', null)
    store.commit('auth/SET_LINE_REGISTERED_MESSAGE_ERR', null)
    next()
  },

  created () {
    if (this.$route.query.invite) {
      this.form.invite_code = this.$route.query.invite
    }

    if (sessionStorage.getItem('previous_route')) {
      sessionStorage.removeItem('previous_route')
    }
  },

  mounted () {
    this.getMessageErrors()
  },

  methods: {
    // Action
    ...mapActions('user', ['userRegisterLink']),
    ...mapActions('auth', ['redirectUserSNS']),

    async getMessageErrors () {
      this.errors = await this.$store.state.auth.messErr
      this.lineRegisteredMessageError = await this.$store.state.auth.lineRegisteredMessError
    },

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.handleSubmit()
      }
    },

    async handleSubmit () {
      const dohBoParams = this.getDohBoParams()
      const hasDohBoParams = Object.keys(dohBoParams).length !== 0

      let params = {
        ...this.form
      }

      if (hasDohBoParams) {
        params = {
          ...params,
          ...dohBoParams
        }
      }

      // handle API SEND MAIL
      const registerResponse = await this.userRegisterLink(params)

      if (registerResponse.status === RESPONSE_STATUS.NO_CONTENT) {
        this.submitted = true
        this.scrollToTop()
      } else {
        this.errors = null

        if (registerResponse.status === RESPONSE_STATUS.UNPROCESSABLE_CONTENT) {
          this.errors = registerResponse.data.errors[0].message[0]
        }

        if (registerResponse.status === RESPONSE_STATUS.BAD_REQUEST) {
          this.errors = registerResponse.data.error.message
        }
      }
    },

    async handleRedirectSNS (service = '') {
      const redirectResponse = await this.redirectUserSNS([service])

      if (this.$route.query.invite) {
        sessionStorage.setItem('invite_code', this.$route.query.invite)
      } else {
        sessionStorage.removeItem('invite_code')
      }

      sessionStorage.setItem('previous_route', this.$route.name)

      if (redirectResponse.success) {
        this.openRedirectSNS(redirectResponse.data.url)
      }
    },

    openRedirectSNS (url) {
      const link = document.createElement('a')
      link.setAttribute('href', url)
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>

<style lang="scss" scoped>
  .step {
    padding: 65px;
    @media screen and (max-width: 768px) {
      border-bottom: 1px solid #CACBC6;
      padding: 48px 18px;
    }
    h4 {
      text-align: center;
      font-weight: 500;
      @include font-size(18px);
      margin-bottom: 65px;
      @media screen and (max-width: 768px) {
        margin-bottom: 40px;
        text-align: left;
      }
    }
    @media screen and (max-width: 768px) {
    }
    &__register {
      display: flex;
      @media screen and (max-width: 768px) {
        display: block;
      }
      .txt__or {
        flex-shrink: 0;
        margin: 0 12px;
        align-self: center;
        @media screen and (max-width: 768px) {
          align-self: unset;
          text-align: center;
          padding: 20px 0;
        }
      }
    }
    &__block {
      background-color: #E7E5DE;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 22px;
      @media screen and (max-width: 768px) {
        padding: 18px;
      }
      ul {
        li {
          color: #282828;
          line-height: 28.8px;
          text-indent: -10px;
          padding-left: 10px;
        }
        &.normal li {
          text-indent: 0px;
          padding-left: 0;
        }
      }
    }
    &__block--mail {
      width: 55%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .btn__register {
        background-color: #6F8784;
        color: #ffffff;
        border-radius: 6px;
        @include font-size(18px);
        font-family: $font-family-title;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 14px;
        cursor: pointer;
        margin-top: 16px;
        margin-bottom: 48px;
        @media screen and (max-width: 768px) {
          margin-top: 12px;
          margin-bottom: 36px;
          padding: 9px 14px;
          font-size: 16px;
        }
        img {
          margin-left: 12px;
        }
      }
    }
    &__block--sn {
      width: 45%;
      background-color: #E7E8DE;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      &.center {
        margin: 0 auto;
      }
      button {
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0 1px 0 #BEBFB9;
        border-radius: 6px;
        @include font-size(18px);
        font-family: $font-family-title;
        display: flex;
        align-items: center;
        padding: 14px;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          font-size: 14px;
          padding: 9px 14px;
        }
        .icon {
          display: inline-flex;
          margin-right: 10px;
          max-width: 26px;
          width: 100%;
        }
      }
      button:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    &__registered {
      background-color: #E7E5DE;
      border-radius: 6px;
      padding: 36px 108px;
      @media screen and (max-width: 768px) {
        padding: 24px 18px;
      }
      .mail {
        background-color: #ffffff;
        text-align: center;
        border-radius: 6px;
        margin-bottom: 25px;
        font-family: $font-family-title;
        color: $default-color;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        word-break: break-all;
        img {
          margin-top: 2px;
          margin-right: 5px;
        }
        @media screen and (max-width: 768px) {
          font-size: 16px;
          padding: 10px 15px;
          text-align: left;
          img {
            margin-right: 10px;
            width: 20px;
          }
        }
      }
      ul {
        li {
          line-height: 28px;
          font-weight: 500;
        }
      }
      .note01 {
        border-bottom: 1px solid #ffffff;
        padding-bottom: 28px;
        margin-bottom: 28px;
        @media screen and (max-width: 768px) {
          padding-bottom: 24px;
          margin-bottom: 24px;
        }
      }
    }
  }
</style>
